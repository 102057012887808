<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        For each of the mathematical expressions shown below, determine the answer and enter your
        response with the appropriate number of significant figures.
      </p>

      <p class="mb-2">a) <stemble-latex :content="expression1" /></p>
      <calculation-input v-model="inputs.input1" class="mb-4" :disabled="!allowEditing" />

      <p class="mb-2">b) <stemble-latex :content="expression2" /></p>
      <calculation-input v-model="inputs.input2" class="mb-4" :disabled="!allowEditing" />

      <p class="mb-2">c) <stemble-latex :content="expression3" /></p>
      <calculation-input v-model="inputs.input3" class="mb-4" :disabled="!allowEditing" />

      <p class="mb-2">d) <stemble-latex :content="expression4" /></p>
      <calculation-input v-model="inputs.input4" class="mb-4" :disabled="!allowEditing" />

      <p class="mb-2">e) <stemble-latex :content="expression5" /></p>
      <calculation-input v-model="inputs.input5" :disabled="!allowEditing" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'SigFigs4',
  components: {
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
    };
  },
  computed: {
    expression1Integer() {
      return this.taskState.getValueBySymbol('expression1Integer').content;
    },
    expression2Integer() {
      return this.taskState.getValueBySymbol('expression2Integer').content;
    },
    expression3Integer() {
      return this.taskState.getValueBySymbol('expression3Integer').content;
    },
    expression4Integer() {
      return this.taskState.getValueBySymbol('expression4Integer').content;
    },
    expression5Integer() {
      return this.taskState.getValueBySymbol('expression5Integer').content;
    },
    expression1() {
      if (this.expression1Integer.value === 1) {
        return '$4.39+10.217$';
      } else if (this.expression1Integer.value === 2) {
        return '$1.26+3.294$';
      } else if (this.expression1Integer.value === 3) {
        return '$0.486+1.7$';
      } else {
        return '$0.4+2.016$';
      }
    },
    expression2() {
      if (this.expression2Integer.value === 1) {
        return '$100.289-50.6$';
      } else if (this.expression2Integer.value === 2) {
        return '$231.8-57.349$';
      } else if (this.expression2Integer.value === 3) {
        return '$18.01-12$';
      } else {
        return '$206.7-184$';
      }
    },
    expression3() {
      if (this.expression3Integer.value === 1) {
        return '$2.8\\times14.7$';
      } else if (this.expression3Integer.value === 2) {
        return '$81.4\\times6.591$';
      } else if (this.expression3Integer.value === 3) {
        return '$17.6\\times4.2$';
      } else {
        return '$186\\times3.741$';
      }
    },
    expression4() {
      if (this.expression4Integer.value === 1) {
        return '$1.234/0.40$';
      } else if (this.expression4Integer.value === 2) {
        return '$17.38/1.26$';
      } else if (this.expression4Integer.value === 3) {
        return '$24.89/7.25$';
      } else {
        return '$37/8.23$';
      }
    },
    expression5() {
      if (this.expression5Integer.value === 1) {
        return '$(18.2-14)/6.3$';
      } else if (this.expression5Integer.value === 2) {
        return '$(8+5.4)\\times5.5$';
      } else if (this.expression5Integer.value === 3) {
        return '$(1.26+0.8)\\times3.0$';
      } else {
        return '$2.50/(1.85+2.1)$';
      }
    },
  },
};
</script>
